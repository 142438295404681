<template>
    <div class="userOverlayout">
    </div>
  </template>
  <script>
  export default {
    name: '',
    data() {
      return {
      }
    },
    created() {
  
    },
    methods: {
  
    },
  }
  </script>
  <style scoped>
  .userOverlayout {
		width: 100%;
		height: 100%;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 999;
		background: rgba(0, 0, 0, 0.30);
	}
  </style>
  